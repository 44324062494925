export const getPickupPoints = async () => {
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    country_id: "75",
    status: 1,
    //city_id: "13124",
    // state_id: "13",
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    console.log("Trayendo data");
    const response = await fetch(process.env.REACT_API_URL, requestOptions);
    const data = await response.json();

    return data;
  } catch (error) {
    console.error("no response", error);
  }
};

export const getPickupPointsV3 = async () => {
  const myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("apikey", process.env.REACT_API_KEY);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    console.log("Trayendo data");
    const url = `${process.env.REACT_API_URL_V2}/filters?country_id=75&status=1`;
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("no response", error);
  }
};
