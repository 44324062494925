const l = Symbol.for("r2wc.reactRender"), h = Symbol.for("r2wc.shouldRender"), f = Symbol.for("r2wc.root");
function w(r = "") {
  return r.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}
function m(r = "") {
  return r.replace(/-([a-z0-9])/g, function(a) {
    return a[1].toUpperCase();
  });
}
var b = {
  expando: function(r, a, s) {
    Object.defineProperty(r, a, {
      enumerable: !0,
      get: function() {
        return s;
      },
      set: function(i) {
        s = i, this[l]();
      }
    }), r[l]();
  }
};
function S(r, a, s, i = {}) {
  var d = {
    isConnected: "isConnected" in HTMLElement.prototype
  }, p = !1, c = function() {
    var t = Reflect.construct(HTMLElement, arguments, this.constructor);
    return typeof i.shadow == "string" ? t.attachShadow({ mode: i.shadow }) : i.shadow && (console.warn(
      'Specifying the "shadow" option as a boolean is deprecated and will be removed in a future version.'
    ), t.attachShadow({ mode: "open" })), t;
  }, u = Object.create(HTMLElement.prototype);
  u.constructor = c;
  var y = new Proxy(u, {
    has: function(t, e) {
      return e in r.propTypes || e in u;
    },
    set: function(t, e, o, n) {
      return p && (d[e] = !0), typeof e == "symbol" || d[e] || e in t ? (r.propTypes && e in r.propTypes && b.expando(n, e, o), Reflect.set(t, e, o, n)) : (b.expando(n, e, o), !0);
    },
    getOwnPropertyDescriptor: function(t, e) {
      var o = Reflect.getOwnPropertyDescriptor(t, e);
      if (o)
        return o;
      if (e in r.propTypes)
        return {
          configurable: !0,
          enumerable: !0,
          writable: !0,
          value: void 0
        };
    }
  });
  return c.prototype = y, u.connectedCallback = function() {
    this[h] = !0, this[l]();
  }, u.disconnectedCallback = function() {
    typeof s.createRoot == "function" ? this[f].unmount() : s.unmountComponentAtNode(this);
  }, u[l] = function() {
    if (this[h] === !0) {
      var t = {};
      Object.keys(this).forEach(function(n) {
        d[n] !== !1 && (t[n] = this[n]);
      }, this), p = !0;
      const e = i.shadow ? this.shadowRoot : this, o = a.createElement(r, t);
      typeof s.createRoot == "function" ? (this[f] || (this[f] = s.createRoot(e)), this[f].render(o)) : s.render(o, e), p = !1;
    }
  }, r.propTypes && (c.observedAttributes = i.dashStyleAttributes ? Object.keys(r.propTypes).map(function(t) {
    return w(t);
  }) : Object.keys(r.propTypes), u.attributeChangedCallback = function(t, e, o) {
    var n = i.dashStyleAttributes ? m(t) : t;
    this[n] = o;
  }), c;
}
export {
  S as default
};
